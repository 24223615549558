import React from 'react'
import './Scanner.css'
import QuickResponseCodeScanner from '../CommonComponents/QuickResponseCodeScanner'

export default function Scanner() {
  return (
    <article>
      <img className='Primera-img' src={require('../../assets/Images/vlts_escaneame.avif')} alt="" />
      <QuickResponseCodeScanner />
    </article>
  )
}
