import React, { Suspense, lazy, useEffect, useState } from "react";
import './Styles/QuickResponseCodeScanner.css'
import { BsChevronCompactUp, BsChevronCompactDown } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useUser from "../../Utils/UserLocalStorage";


const Scanner = lazy(() => import("react-webcam-qr-scanner"));

const QuickResponseCodeScanner = () => {
  const navigate = useNavigate();
  const { logout, token: userToken } = useUser();
  const [showQR, setShowQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataQR, setDataQR] = useState('');
  const [payloadStatus, setPayloadStatus] = useState(0);

  useEffect(() => {
    setShowQR(true)
  }, []);


  async function handleDecode(result) {
    setLoading(true)
    setShowQR(false)

    let res = result.data.split('*')
    setDataQR(res);

    const obj = {
      "userQr": res[1],
      'userToken':userToken.replace('"', '').replace('"', ''),
      "userTerm": res[2],
      "userTermName": res[3],
      "userAgencia": res[4],
      "userParque": res[5]
    }

    await axios.post(process.env.REACT_APP_API_URL + `/users/reading`, obj)
      .then((response) => {
        let res = response.data
        setDataQR(res)
        setPayloadStatus(res.status)

      }, (error) => {
        setLoading(false)
        console.error( error)
      });
  }

  function whatMustShow() {

    if (payloadStatus === '600') {
      return (
        <section className="ModalLoading">
          <div className="succesResponse-img">
            <img className="Aparece-animation" src={require('../../assets/Images/cartelPuntos.avif')} alt="" />
            <button onClick={() => navigate('/home')}>
              Continuar
            </button>
          </div>
        </section>
      )
    }


    if (payloadStatus === '601') {
      return <ErrorMessage data={dataQR} onClick={logout} />
    }

    if (payloadStatus === '602') {
      return <ErrorMessage data={dataQR} onClick={() => { window.location.reload() }} />
    }


    if (loading) {
      return (
        <section className="ModalLoading">
          <AiOutlineLoading3Quarters className="iconLoading Giro" />
        </section>
      )
    }

    return (
      <article className="collapsable-box">
        <div className="collapsable-btn-action" onClick={() => setShowQR(!showQR)}>
          {showQR ? <BsChevronCompactDown /> : <BsChevronCompactUp />}
        </div>
        <div className="collapsable-box-head">
          <h1>Escanea el cuadro en la pantalla</h1>
        </div>

        <div className={showQR ? 'ContainerQR ContainerQR-Active' : 'ContainerQR'}>
          <div className="ScanerBox">
            {showQR &&
              <Suspense>
                <Scanner
                  className="some-classname"
                  onDecode={handleDecode}
                  resolution={600}
                  legacyMode
                  constraints={{
                    audio: false,
                    video: {
                      facingMode: "environment"
                    }
                  }}
                />
              </Suspense>
            }
          </div>
          <p>{dataQR}</p>
          <br /><br />
        </div>
      </article>
    )
  }

  return whatMustShow()
}


function ErrorMessage({ data, onClick }) {
  return (
    <section className="ModalLoading">
      <div className="card-component ErrorMessage">
        <h4>{data.message}</h4>
        <button onClick={() => onClick()}>
          Pulsa para reintentar
        </button>
      </div>
    </section>

  )
}

export default (QuickResponseCodeScanner)