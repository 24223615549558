import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import './Login.css'
import Input from '../CommonComponents/Input';
import { isDniValid, isEmailValid, isPasswordValid, isPhoneValid } from '../../Utils/validations';

function SingUp() {
  const navigate = useNavigate()

  const [userName, setUserName] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [userTel, setUserTel] = useState(null)
  const [userDni, setUserdni] = useState(null)
  const [userDomi, setUserDomi] = useState(null)
  const [userPass, setUserPass] = useState(null)
  const [loading, setLoading] = useState(false)

  const [responseMessage, setResponseMessage] = useState(null)
  const [errors, setErrors] = useState({}); // To hold validation errors


  function validateObj() {
    const newErrors = {};

    if (!isEmailValid(userEmail)) newErrors.email = "Email no válido";
    if (!userName) newErrors.userName = "Nombre es requerido";
    if (!userDomi) newErrors.userDomi = "Domicilio es requerido";
    if (!isDniValid(userDni)) newErrors.userDni = "DNI es incorrecto";
    if (!isPhoneValid(userTel)) newErrors.userTel = "Teléfono debe tener mas de 7 dígitos";
    if (!isPasswordValid(userPass)) newErrors.userPass = "La contraseña debe tener entre 8 y 16 caracteres";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      registraUser();
    }
  }

  async function registraUser() {
    setLoading(true)

    const obj = {
      'action': 'registro',
      'userName': userName,
      'userDomi': userDomi,
      'userEmail': userEmail,
      'userTel': userTel,
      'userDni': userDni,
      'userPass': userPass,
    }

    await axios.post(process.env.REACT_APP_API_URL + `/users/registro`, obj)
      .then((response) => {

        let res = response.data;
        if (res[0].ok)
          setResponseMessage(res[1].message);
        else
          alert(res[1].message);
      }, (error) => {
        if (error.response?.data && error.response.data[1].message)
          alert(error.response.data[1].message)
        else
          alert("Error al iniciar session.")
      }).finally(() => setLoading(false));
  }

  return (
    <article className="registro-page">
      <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />

      {
        responseMessage ?
          <div className='box-container message-box'>
            <div className="card-component">
              <img src={require('../../assets/Images/emailValidation.avif')} alt="" />
              <h4 className='message'>{responseMessage}</h4>
            </div>
            <button className='bg-rojo txt-white' onClick={() => navigate('/login')}>Iniciar Sesión</button>
          </div>
          :
          <div className='box-container'>
            <form className="card-component" autoComplete='off'>
              <Input
                label="Nombre"
                type="text"
                placeholder="Juana Molina"
                name='name'
                value={userName}
                setValue={setUserName}
                error={errors.userName}
              />

              <Input
                label="Domicilio"
                type="address"
                placeholder="Cale 1234 CABA"
                name='address'
                value={userDomi}
                setValue={setUserDomi}
                error={errors.userDomi}
              />

              <Input
                label="DNI"
                type="number"
                placeholder="..."
                maxLength={8}
                value={userDni}
                setValue={setUserdni}
                error={errors.userDni}
              />

              <Input
                label="Teléfono"
                type="number"
                placeholder="..."
                maxLength={10}
                value={userTel}
                setValue={setUserTel}
                error={errors.userTel}
              />

              <Input
                label="Email"
                type="email"
                placeholder="Tu correo@electronico.com"
                name="email"
                value={userEmail}
                setValue={setUserEmail}
                error={errors.email}
              />

              <Input
                label="Password"
                type="password"
                placeholder="8 a 16 Caracteres"
                value={userPass}
                setValue={setUserPass}
                error={errors.userPass}
              />
            </form>

            <div className="margin-16">
              {
                loading ?
                  <div className='loading-icon'>
                    <AiOutlineLoading3Quarters className='Giro' />
                  </div>
                  :
                  <button className='bg-violeta txt-white' onClick={() => validateObj()}>Registrarse</button>
              }

              <br />

              <div className="width-100 rowFlex spaceBetween margin-top-8">
                <div className="link-text rowFlex text-white" onClick={() => navigate('/home')}>Volver</div>
                <p className='link-text text-white pipeElement'>|</p>
                <div className='link-text text-white' onClick={() => navigate('/login')}>Ya tengo cuenta</div>
              </div>
            </div>
          </div>
      }
    </article>
  )
}

export default (SingUp);