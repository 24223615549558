import React, { useEffect, useState } from 'react'
import useUser from '../../Utils/UserLocalStorage'
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import './Premios.css'
import axios from 'axios';
import BoxCanje from './BoxCanje';

export default function Premios() {
  const { puntaje: misPuntos, token: userToken } = useUser()
  const [loading, setLoading] = useState(false)
  const [premios, setPremios] = useState([])
  const [premioElegido, setPremioElegido] = useState(null)

  const [validancia, setValidancia] = useState(null)


  const [response, setResponse] = useState(null)
  const [statusPayload, setStatusPayload] = useState(null)


  async function canjearPuntos() {
    setLoading(true)

    await axios.post(process.env.REACT_APP_API_URL + `/productos/canjear`, {
      'userToken': userToken.replace('"', '').replace('"', ''),
      'idPremios': premioElegido.id
    })
      .then((response) => {

        let res = response.data;
        setResponse(res)
        setStatusPayload(res.status)

      }, (error) => {
        setLoading(false)
        console.error( error)
      });

  }

  async function getPremios() {
    setLoading(true)

    await axios.get(process.env.REACT_APP_API_URL + `/productos/getproductos`)
      .then((response) => {

        let res = response.data;
        setLoading(false)
        setPremios(res.data)

      }, (error) => {
        setLoading(true)
        console.error( error)
      });

  }

  useEffect(() => {
    getPremios()
  }, []);


  if (statusPayload === 'precanje') {
    return (
      <div>
        <BoxCanje onclick={(premio) => { setStatusPayload('precanje'); setPremioElegido(premio) }} misPuntos={misPuntos} premios={premios} />

        <section className="ModalPremio">
          <div>
            <div className="card-component Validancia">
              <div className='card-head'>Vas a canjear {premioElegido.puntos + ' de tus puntos'}</div>
              <div className='canje-info'>
                <div className="rowFlex">
                  <img src={require('../../assets/Images/img-premios/' + premioElegido.url)} alt="" />
                  <h4>{premioElegido.nombre}</h4>
                </div>
                <p>{premioElegido.descripcion}</p>
              </div>
              <button
                className='bg-rojo txt-white'
                disabled={misPuntos < premioElegido.puntos}
                onClick={() => canjearPuntos()}
              >
                canjear
              </button>
            </div>
            <div className='cancelar-modal' onClick={() => { setStatusPayload(null); setPremioElegido(null) }}>Cancelar</div>
          </div>
        </section>
      </div>
    )
  }


  if (statusPayload === '701') {
    return (
      <section className="ModalLoading">
        <div className="card-component canje-messaje">
          <h4>{response ? response.message : 'Puntos insuficientes'}</h4>
          <button className='bg-rojo txt-white' onClick={() => window.location.reload()}>Entendido</button>
        </div>
      </section>
    )
  }

  if (loading) {
    return (
      <section className="ModalLoading">
        <AiOutlineLoading3Quarters className="iconLoading Giro" />
      </section>
    )
  }

  return (
    <BoxCanje onclick={(premio) => { setStatusPayload('precanje'); setPremioElegido(premio) }} misPuntos={misPuntos} premios={premios} />
  )
}
