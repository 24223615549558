/**
 * Validation utility functions for form inputs
 */

// RFC 5322 compliant email regex
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// DNI regex for numbers only between 7-9 digits
const DNI_REGEX = /^\d{7,9}$/;

// Phone regex for numbers only, minimum 8 digits
const PHONE_REGEX = /^\d{8,}$/;

/**
 * Validates an email address
 * @param {string} email - The email to validate
 * @returns {boolean} True if email is valid
 */
export const isEmailValid = (email) => {
  return isNotEmpty(email) && EMAIL_REGEX.test(email.trim());
};

/**
 * Validates a DNI (Documento Nacional de Identidad)
 * @param {string|number} dni - The DNI to validate
 * @returns {boolean} True if DNI is valid
 */
export const isDniValid = (dni) => {
  if (!dni) return false;
  return isNotEmpty(dni.toString()) && DNI_REGEX.test(dni.toString().trim());
};

/**
 * Validates a phone number
 * @param {string|number} phone - The phone number to validate
 * @returns {boolean} True if phone is valid
 */
export const isPhoneValid = (phone) => {
  if (!phone) return false;
  return isNotEmpty(phone.toString()) && PHONE_REGEX.test(phone.toString().trim());
};

/**
 * Validates a password
 * @param {string} password - The password to validate
 * @returns {boolean} True if password meets requirements
 */
export const isPasswordValid = (password) => {
  if (!isNotEmpty(password)) return false;
  
  const trimmedPassword = password.trim();
  
  // Length between 8-16 characters
  if (trimmedPassword.length < 8 || trimmedPassword.length > 16) return false;
  
  // Must contain at least one number
//   if (!/\d/.test(trimmedPassword)) return false;
  
  // Must contain at least one letter
//   if (!/[a-zA-Z]/.test(trimmedPassword)) return false;
  
  return true;
};

/**
 * Validates if a string is empty or only whitespace
 * @param {string} value - The string to validate
 * @returns {boolean} True if string is not empty or whitespace
 */
export const isNotEmpty = (value) => {
  if (!value || typeof value !== 'string') return false;
  return value.trim().length > 0;
};
