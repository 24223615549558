import React, { createContext, useState, useContext, useEffect } from 'react';
import decode from 'jwt-decode';

const TOKEN_NAME = 'fidelizacion_token';

// Create the context
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [nombre, setNombre] = useState('');
  const [puntaje, setPuntaje] = useState('');
  const [userData, setUserData] = useState(null);

  // Load data from sessionStorage on initial load
  useEffect(() => {
    const storedToken = sessionStorage.getItem(TOKEN_NAME);
    const storedNombre = sessionStorage.getItem('nombre');
    const storedPuntaje = sessionStorage.getItem('puntaje');

    if (storedToken) {
      setToken(JSON.parse(storedToken));
    }
    if (storedNombre) {
      setNombre(JSON.parse(storedNombre));
    }
    if (storedPuntaje) {
      setPuntaje(JSON.parse(storedPuntaje));
    }
  }, []);

  // Method to set token and related user data
  const handleSetToken = (idToken, nombreUser, puntajeUser) => {
    sessionStorage.setItem(TOKEN_NAME, JSON.stringify(idToken));
    sessionStorage.setItem('nombre', JSON.stringify(nombreUser));
    sessionStorage.setItem('puntaje', JSON.stringify(puntajeUser));

    setToken(idToken);
    setNombre(nombreUser);
    setPuntaje(puntajeUser);
  };

  // Method to update puntaje
  const handleSetPuntaje = (newPuntaje) => {
    sessionStorage.setItem('puntaje', JSON.stringify(newPuntaje));
    setPuntaje(newPuntaje);
  };

  // Check if user is logged in
  const loggedIn = () => {
    return !!token && !isTokenExpired(token);
  };

  // Check if token is expired
  const isTokenExpired = (tokenToCheck) => {
    try {
      const decoded = decode(tokenToCheck);
      return decoded.exp * 1000 < Date.now() / 1000;
    } catch (err) {
      return false;
    }
  };

  // Get user data from token
  const getUserData = () => {
    try {
      const decoded = decode('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' + token + '.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c');
      setUserData(decoded);
      return decoded;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  // Logout method
  const logout = () => {
    sessionStorage.removeItem(TOKEN_NAME);
    sessionStorage.removeItem('puntaje');
    sessionStorage.removeItem('nombre');

    setToken(null);
    setNombre('');
    setPuntaje('');
    setUserData(null);
  };

  // Decode token method
  const decodeToken = (tokenToDecode) => {
    try {
      const decoded = decode('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' + tokenToDecode + '.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c');
      
      if (isTokenExpired(decoded)) {
        return 'token expirado';
      } else {
        return decoded.data;
      }
    } catch (error) {
      return null;
    }
  };

  // Context value
  const contextValue = {
    token,
    nombre,
    puntaje,
    userData,
    setToken: handleSetToken,
    setPuntaje: handleSetPuntaje,
    loggedIn,
    getUserData,
    logout,
    decodeToken
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export default useUser