import React, { useEffect, useState } from 'react'
import './Navbar.css'
import { useNavigate } from "react-router-dom";
import { BsHouse, BsStar, BsTrophy, BsPower, BsPerson } from "react-icons/bs";
import { MdOutlineQrCode } from "react-icons/md";
import useUser from '../../Utils/UserLocalStorage';
import axios from 'axios';

export default function Navbar() {
  const [counter, setCounter] = useState(0);

  const navigate = useNavigate()
  const {loggedIn, nombre , puntaje, logout, token: userToken, setPuntaje} = useUser();

  async function validateLive() {

    await axios.post(process.env.REACT_APP_API_URL + `/users/live`, {
      'userToken': userToken.replace('"', '').replace('"', ''),
      'action': 'stillALive',
    })
      .then((response) => {

        let res = response.data;
        if (res && res.status === '800') {
          setPuntaje(Math.round(res.puntos))
        }
        if (res && res.status !== '800') {
          alert('¡Oops! ' + res.message)
          logout()
        }

      }, (error) => {
        console.error( error)
      });

  }

  useEffect(() => {
    counter < 86400 && setTimeout(() => {
      setCounter(counter + 1)
      if (!!userToken) {
        validateLive()
      }
    }
      , 5000);
  }, [counter]);


  return (
    <header className={loggedIn() ? "navbar-component navbar-logueado" : "navbar-component"}>

      {nombre && puntaje !== undefined &&
        <div className="userPuntaje rowFlex spaceBetween">
          <div>
            <h1>Hola {nombre && (nombre.replace('"', '')).replace('"', '')}</h1>
            <h4>Puntos: {puntaje && puntaje}</h4>
          </div>
          <div className="emoji" />
        </div>
      }
      <nav className="rowFlex spaceBetween alignStart">
        <img className="navbar-logo" src={require('../../assets/Images/logoFinal.avif')} alt="Logo" />
        {loggedIn() && <ul className="rowFlex navbar-box">
          <li className="navbar-item" onClick={() => navigate('/home')}>
            <BsHouse />
            <span>Inicio</span>
          </li>

          <li className="navbar-item" onClick={() => navigate('/user')}>
            <BsPerson />
            <span>Perfil</span>
          </li>

          <li className="navbar-item" onClick={() => navigate('/qr-scanner')}>
            <MdOutlineQrCode />
            <span>Escaneá</span>
          </li>

          <li className="navbar-item" onClick={() => navigate('/puntos')}>
            <BsStar />
            <span>Puntos</span>
          </li>
          {false &&
            <li className="navbar-item" onClick={() => navigate('/puntos')}>
              <BsStar />
              <span>Canjes</span>
            </li>

          }
          <li className="navbar-item" onClick={() => navigate('/premios')}>
            <BsTrophy />
            <span>Premios</span>
          </li>
          <li className="navbar-item" onClick={logout}>
            <BsPower />
            <span>Salir</span>
          </li>
        </ul>}
      </nav>
    </header>
  )
}
