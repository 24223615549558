import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import './Login.css'
import useUser from '../../Utils/UserLocalStorage';
import Input from '../CommonComponents/Input'; // Import the Input component
import { isEmailValid } from '../../Utils/validations';

function Login() {
  const navigate = useNavigate()
  const [userLogin, setUserLogin] = useState('')
  const [passLogin, setPassLogin] = useState('')
  const [loading, setLoading] = useState(false)

  const [response, setResponse] = useState(null)
  const [message, setMessage] = useState(null)
  const [mailValido, setMailValido] = useState(true)
  const { setToken } = useUser();


  function validateObj() {

    if (isEmailValid(userLogin)) {
      setMailValido(true)
      loginUser()
    } else {
      setMailValido(false)
      console.error('mail no valido')
    }
  }


  async function loginUser() {

    setLoading(true)
    const obj = {
      'email': userLogin,
      'pass': passLogin
    }

    await axios.post(process.env.REACT_APP_API_URL + `/users/login`, obj)
      .then((response) => {

        const res = response.data;
        if (res.status == '601') {
          setToken(res.pasar, res.nombre, Math.round(res.puntos))
          navigate('/home')
        } else {// falta terminar el registro
          setResponse(true)
          setMessage(res.message)
        }


      }, (error) => {
        setLoading(false)
        console.error(error)
        alert("Error al ingresar, por favor intenta de nuevo")
      });
  }

  if (!!response) {
    return (
      <article className="registro-page">
        <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />

        <div className='box-container message-box'>
          <div className="card-component">
            {response == '603' && <img src={require('../../assets/Images/emailValidation.avif')} alt="" />}
            <h4 className='message'>{message}</h4>
          </div>
          <button className='bg-rojo txt-white' onClick={() => window.location.reload()}>Ingresar</button>
        </div>
      </article>
    )
  }

  return (
    <article className="registro-page">
      <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />

      <div className='box-container'>
        <div className="card-component">
          <Input
            label="Ya tengo cuenta"
            type="email"
            name="email"
            autoComplete="off"
            placeholder="Tu correo@electronico.com"
            value={userLogin}
            setValue={setUserLogin}
            error={!mailValido && "Mail no valido"}
          />
          <Input
            type="password"
            name="password"
            placeholder="Contraseña"
            value={passLogin}
            setValue={setPassLogin}
            onEnter={validateObj}
          />
        </div>

        <div className='margin-16 link-text text-white forgetPass' onClick={() => navigate('/forget-pass')}>Olvidé mi Contraseña</div>

        <div className="margin-16">
          {
            loading ?
              <div className='loading-icon'>
                <AiOutlineLoading3Quarters className='Giro' />
              </div>
              :
              <button className="bg-rojo txt-white" onClick={() => validateObj()}>Ingresar</button>
          }
          <div className="width-100 rowFlex	spaceBetween margin-top-16">
            <div className="link-text text-white" onClick={() => navigate('/home')}>Volver</div>
            <p className='link-text text-white pipeElement'>|</p>
            <div className='link-text text-white' onClick={() => navigate('/sing-up')}>Registrarse</div>
          </div>
        </div>
      </div>
    </article>
  )
}

function RegistrateButton() {
  const navigate = useNavigate()

  return (
    <button className="bg-rojo txt-white" onClick={() => navigate('/sing-up')}>Registrarse</button>
  )
}

function LoginButton() {
  const navigate = useNavigate()

  return (
    <button className="bg-violeta txt-white" onClick={() => navigate('/login')}>
      Ingresar
    </button>
  )
}

export default (Login);
export { RegistrateButton, LoginButton }