import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'

export default function Welcome() {
  const navigate = useNavigate()
  const location = useLocation()
  const [activationStatus, setActivationStatus] = useState({
    loading: true,
    success: false,
    error: null
  })

  useEffect(() => {
    // Parse query parameters
    const searchParams = new URLSearchParams(location.search)
    const codigo = searchParams.get('codigo')
    const emailUsuario = searchParams.get('emailUsuario')

    // Validate email if parameters exist
    if (codigo && emailUsuario) {
      validateEmail(codigo, emailUsuario)
    } else {
      setActivationStatus({
        loading: false,
        success: false,
        error: 'Parámetros de activación inválidos'
      })
    }
  }, [location])

  const validateEmail = (codigo, emailUsuario) => {
    axios.post(process.env.REACT_APP_API_URL + '/users/validateMail', {
      codigo,
      emailUsuario
    }).then((response) => {
      const res = response.data
      setActivationStatus({
        loading: false,
        success: res[0].ok,
        error: res[1].message
      })
    }, (error) => {
      const res = (error.response?.data && error.response.data[1].message) ? error.response.data : [{ ok: false }, { message: 'Error al activar la cuenta' }]
      setActivationStatus({
        loading: false,
        success: res[0].ok,
        error: res[1].message
      })
    });
  }

  const handleLogin = () => {
    navigate('/login')
  }

  // Render loading state
  if (activationStatus.loading) {
    return (
      <article className='welcome'>
        <section className="registro-page">
          <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />
          <div className='box-container message-box'>
            <div className="card-component">
              <p>Activando cuenta...</p>
            </div>
          </div>
        </section>
      </article>
    )
  }

  // Render based on activation status
  return (
    <article className='welcome'>
      <section className="registro-page">
        <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />

        <div className='box-container message-box'>
          {activationStatus.success ? (
            <div className="card-component">
              <img src={require('../../assets/Images/emailActivation.avif')} alt="" />
              <h4 className='message'>Listo, ya activaste tu cuenta</h4>
            </div>
          ) : (
            <div className="card-component">
              <h4 className='message'>Error de activación</h4>
              <p className='message'>{activationStatus.error}</p>
            </div>
          )}
          {activationStatus.success && (
            <button
              className='bg-rojo txt-white'
              onClick={handleLogin}
            >
              Ingresar
            </button>)
          }
        </div>
      </section>
    </article>
  )
}