import React, { useState } from "react";
import './assets/Styles/Styles.css'
import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";
import ViewNotFound404 from "./Components/Routes/ViewNotFound404";
import RoutesUrls, { PrivateRoutes } from "./Components/Routes/Routes";
import useUser from "./Utils/UserLocalStorage";

function App() {
  const { loggedIn } = useUser()
  return (
    <BrowserRouter>
      {loggedIn() ?
        <Routes>
          {PrivateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={<route.main />}
            />
          ))}
          <Route
            path="/*"
            element={<NoMatch />}
          />

        </Routes>
        :
        <Routes>
          {RoutesUrls.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={<route.main />}
            />
          ))}
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      }
    </BrowserRouter>
  );
}

const NoMatch = () => {
  return (
    <ViewNotFound404 />
  );
}

export default (App)