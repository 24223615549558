import axios from 'axios';
import React, { useEffect, useState } from 'react'
import useUser from '../../Utils/UserLocalStorage';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import './Puntos.css'

export default function Puntos() {

  const [loading, setLoading] = useState(true)
  const [dataPuntos, setDataPuntos] = useState([])
  const [totalPuntos, setTotalPuntos] = useState(0)
  const { token: UserToken } = useUser()

  async function getPuntos() {
    await axios.post(process.env.REACT_APP_API_URL + `/users/getpuntos`, {
      'action': 'getPuntos',
      'userToken': UserToken.replace('"', '').replace('"', '')
    })
      .then((response) => {

        let res = response.data;

        if (res.status == '701') {
          setLoading(false)
          setDataPuntos(res.data)

          let suma = 0

          for (let i = 0; i < res.data.length; i++) {
            suma = suma + res.data[i].puntos
          }
          setTotalPuntos(suma)
        } else {
          console.error('Error al obtener Puntos')
        }


      }, (error) => {
        setLoading(true)
        console.error(error)

      });
  }


  useEffect(() => {
    getPuntos()
  }, []);

  if (loading) {
    return (
      <section className="ModalLoading">
        <AiOutlineLoading3Quarters className="iconLoading Giro" />
      </section>
    )
  }

  return (
    <article className='Puntos-Page'>
      <br />

      <div className='caja-puntos'>
        <h1>
          Detalle de puntos
        </h1>
      </div>
      <table>
        <thead>
          <th>Fecha</th>
          <th>tipo</th>
          <th>Puntos</th>
        </thead>
        <tbody className="rojo">
          {dataPuntos.map((punto, p) =>
            Math.round(punto.puntos) > 0 || punto.tipocanje === 'canje producto' ?
              <tr key={p}>
                <td>{punto.fecha}</td>
                <td>{punto.tipocanje}</td>
                <td>{Math.round(punto.puntos)}</td>
              </tr>
              : null
          )}
        </tbody>
        <tfoot>
          <td colSpan={2}>Puntos acumulados</td>
          <td>{Math.round(totalPuntos)}</td>
        </tfoot>
      </table>

    </article>
  )
}
