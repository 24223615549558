import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import './Login.css';
import Input from '../CommonComponents/Input'; // Importing Input component
import { isEmailValid, isPasswordValid } from '../../Utils/validations';

function ForgetPass() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const { codigo, emailUsuario } = useParams();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  const [responseMessage, setMessage] = useState(null);
  const [errors, setErrors] = useState({});

  const handleRecoveryPass = async () => {
    setLoading(true);
    let newErrors = {};
    if (!!codigo) {
      if (password1 !== password2) newErrors.pass = "Las contraseñas deben ser iguales"
      if (!isPasswordValid(password1)) newErrors.pass = "Las contraseña deben tener entre 8 y 16 caracteres"
    } else
      if (!isEmailValid(userEmail))
        newErrors.mail = "El email es incorrecto"
    setErrors(newErrors)

    if (Object.keys(newErrors).length === 0) {
      const obj = codigo ?
        { emailUsuario, codigo, password: password1 } :
        { emailUsuario: userEmail };
      const endpoint = codigo ? '/users/changePass' : '/users/forgetPass';

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}${endpoint}`, obj);
        setResponseStatus(response.data[0].ok);
        setMessage(response.data[1].message);
      } catch (error) {
        console.error(error);
        if (error.response?.data && error.response.data[1].message) {
          setResponseStatus(error.response.data[0].ok);
          setMessage(error.response.data[1].message)
        }
        else {
          setResponseStatus(false);
          setMessage('An error occurred. Please try again.');
        }
      }
    }
    setLoading(false);
  };

  return (
    <article className="registro-page">
      <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="Logo" />
      {responseMessage ? (
        <div className='box-container message-box'>
          <div className="card-component">
            {!codigo && responseStatus && <img src={require('../../assets/Images/emailValidation.avif')} alt="Validation" />}
            <h4 className='message'>{responseMessage}</h4>
          </div>
          <button className='bg-rojo txt-white' onClick={() => responseStatus ? navigate('/login') : window.location.reload()}>{responseStatus ? 'Ingresar' : 'Reintentar'}</button>
        </div>
      ) : (
        <div className='box-container message-box'>
          <div className="card-component">
            {codigo ? (
              <>
                <Input
                  label="Nueva contraseña"
                  type="password"
                  name="password1"
                  value={password1}
                  setValue={setPassword1}
                  placeholder="Tu nueva contraseña"
                  error={errors.pass}
                />
                <Input
                  label="Repetir contraseña"
                  type="password"
                  name="password2"
                  value={password2}
                  setValue={setPassword2}
                  placeholder="Repetir contraseña"
                  onEnter={handleRecoveryPass}
                />
              </>
            ) : (
              <Input
                label="Tu email"
                type="email"
                name="email"
                value={userEmail}
                setValue={setUserEmail}
                placeholder="Tu correo@electronico.com"
                error={errors.mail}
                onEnter={handleRecoveryPass}
              />
            )}
          </div>
          <div className="margin-16">
            {loading ? (
              <AiOutlineLoading3Quarters />
            ) : (
              <button className={`bg-${!codigo ? 'rojo' : 'violeta'} txt-white`} onClick={handleRecoveryPass}>
                Cambiar contraseña
              </button>
            )}
            <div className="width-100 rowFlex spaceBetween margin-top-16">
              <div className="link-text rowFlex text-white" onClick={() => navigate('/login')}>Cancelar</div>
            </div>
          </div>
        </div>
      )}
    </article>
  );
}

export default ForgetPass;