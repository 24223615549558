import React, { useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import './User.css'
import axios from 'axios';
import useUser from '../../Utils/UserLocalStorage';
import Input from '../CommonComponents/Input';
import { isPasswordValid, isPhoneValid } from '../../Utils/validations';

export default function User() {
  const { token: UserToken } = useUser()

  const [userName, setUserName] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [userTel, setUserTel] = useState(null)
  const [userDni, setUserdni] = useState(null)
  const [userAddress, setUserAddress] = useState(null)
  const [userPass, setUserPass] = useState(null)
  const [userPass2, setUserPass2] = useState(null)
  const [loading, setLoading] = useState(false)

  const [response, setResponse] = useState(false)
  const [message, setMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(false)
  const [inputErrors, setErrors] = useState({})

  function validateObj() {
    const newErrors = {};
    if (!userName) newErrors.userName = "Nombre es requerido";
    // if (!userAddress) newErrors.userAddress = "Domicilio es requerido";
    if (!isPhoneValid(userTel)) newErrors.userTel = "Teléfono debe tener mas de 7 dígitos";
    if (userPass !== userPass2) newErrors.userPass = 'Las contraseñas no coinciden.';
    if (!isPasswordValid(userPass)) newErrors.userPass = "La contraseña debe tener entre 8 y 16 caracteres";
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      editaUser();
    }
  }

  async function editaUser() {
    setLoading(true)

    const obj = {
      'userToken': UserToken.replace('"', '').replace('"', ''),
      'userName': userName,
      'userAddress': userAddress,
      'userEmail': userEmail,
      'userTel': userTel,
      'userDni': userDni,
      'userPass': userPass
    }

    await axios.put(process.env.REACT_APP_API_URL + `/users/update`, obj)
      .then((response) => {

        let res = response.data;

        setResponse(false)
        setResponse(true)
        setErrorMessage(res.ok)
        setMessage(res.message)
        setLoading(false)
      }, (error) => {
        setResponse(false)
        setResponse(true)
        setErrorMessage(false)
        setMessage("¡Ups!, ah ocurrido un error")
        setLoading(false)
      });
  }

  async function getUser(token) {
    setLoading(true)
    await axios.get(process.env.REACT_APP_API_URL + '/users/getUser/?userToken=' + token.replace('"', '').replace('"', ''))
      .then((response) => {
        let res = response.data.obj

        setUserName(res.nombre)
        setUserAddress(res.direccion)
        setUserdni(res.dni)
        setUserEmail(res.email)
        setUserTel(res.telefono)

        setLoading(false)
      }, (error) => {
        setLoading(false)
        console.error(error)
      });
  }

  useEffect(() => {
    getUser(UserToken)
  }, []);

  return (
    <section className="editUser-page">

      <div className='caja-puntos'>
        <h1>
          Perfil de usuario
        </h1>
      </div>

      {response ?
        <div className='box-container message-box'>
          <div className={`card-component ${!errorMessage ? 'error' : ''}`}>
            <h4 className='message'>{message}</h4>
          </div>
        </div>
        : null
      }

      <div className='box-container'>
        <form className="card-component" autoComplete='off'>
          <Input
            label="Nombre"
            name="name"
            placeholder="Juana Molina"
            setValue={setUserName}
            value={userName}
            error={inputErrors.userName}
          />
          <Input
            label="Domicilio"
            name="address"
            type="address"
            placeholder="Cale 1234 CABA"
            setValue={setUserAddress}
            value={userAddress}
            error={inputErrors.userAddress}
          />
          <Input
            label="DNI"
            name="dni"
            type="number"
            disabled={true}
            placeholder="11111111"
            maxLength={8}
            setValue={setUserdni}
            value={userDni}
            error={inputErrors.userDni}
          />
          <Input
            label="Teléfono"
            name="tel"
            type="number"
            maxLength={10}
            setValue={setUserTel}
            value={userTel}
            error={inputErrors.userTel}
          />
          <Input
            label="Email"
            name="email"
            type="email"
            disabled
            placeholder="Tu correo@electronico.com"
            setValue={setUserEmail}
            value={userEmail}
            error={inputErrors.userEmail}
          />

          {/* Password inputs */}
          <Input
            label="Nueva contraseña"
            name="password1"
            type="password"
            placeholder="Nueva contraseña"
            setValue={setUserPass}
            error={inputErrors.userPass}
          />
          <Input
            label="Repetir contraseña"
            name="password2"
            type="password"
            placeholder="Repetir contraseña"
            setValue={setUserPass2}
          />
        </form>
        <div className="margin-16">
          {
            loading ?
              <div className='loading-icon'>
                <AiOutlineLoading3Quarters className='Giro' />
              </div>
              :
              <button className='bg-violeta txt-white' onClick={() => validateObj()}>Guardar</button>
          }
        </div>

      </div>
    </section>
  )
}

