import React from 'react';
import './Styles/Input.css';

const Input = ({
  label,
  type = 'text',
  name,
  value,
  setValue,
  error,
  className = '',
  onEnter,
  onKeyUp,
  ...otherProps
}) => {
  const handleKeyUp = (event) => {
    if (!!onKeyUp) {
      onKeyUp(event);
    }
    if (!!onEnter && event.key === 'Enter') {
      onEnter();
    }
  };

  return (
    <fieldset>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        type={type}
        name={name}
        id={name}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        className={className + (error ? 'invalidInput' : '')}
        onKeyUp={handleKeyUp}
        aria-invalid={!!error}
        {...otherProps}
      />
      {error && <div className='errorMessage' role="alert">{error}</div>}
    </fieldset>
  );
};

export default Input;
